export const DEFAULT_LOCATION = {
  NYC: {
    lat: 40.748817,
    lng: -73.985428,
    states: ["NY", "NJ", "CT", "MA", "PA", "NH", "VT", "DE", "MD"],
  },
  LA: {
    lat: 34.058605,
    lng: -118.413124,
    states: ["CA", "WA", "OR", "NV", "AZ", "UT", "CO", "ID", "MT", "WY", "NM"],
  },
};
