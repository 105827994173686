export enum SearchTypes {
  Venue = "venue",
  Vendor = "vendor",
}

export enum SearchFileTypes {
  FloorPlan = "floor_plan",
  TearSheet = "tear_sheet",
}

export enum InquiryTypes {
  Venue = SearchTypes.Venue,
  Vendor = SearchTypes.Vendor,
  Package = "package",
  General = "general",
}

export enum DashboardMobileDialogContentTypes {
  RenderRightSidebarContainer = "renderRightSidebarContainer",
  RenderFAQContainer = "renderFAQContainer",
  RenderMobileDialogManager = "renderMobileDialogManager",
}

export enum InquiryFilters {
  Open = "open",
  Booked = "booked",
  Closed = "closed",
  Canceled = "canceled",
  Completed = "completed",
  EventValue = "eventValue",
  StartDateValue = "startDateValue",
  EndDateValue = "endDateValue",
  InquiryId = "inquiryId",
}

export enum InquirySecondaryStatusLabels {
  Due = "due",
  Overdue = "overdue",
  Processing = "processing",
}

export enum InquiryStatusLabels {
  Open = "open",
  Booked = "booked",
  Сompleted = "completed",
  Closed = "closed",
  Planning = "planning",
  Draft = "draft",
}

export enum InquiryLogTitles {
  inquirySubmitted = "Inquiry submitted",
  inquiryChanged = "Event details changed",
  inquiryClosed = "Inquiry closed",
  inquiryReopened = "Inquiry reopened",
  inquiryBooked = "Booking Confirmed",
  inquiryFeedbackNonSubmitted = "Feedback",
  inquiryFeedbackSubmitted = "Thank you for your feedback!"
}

export enum InquiryLogSourceTypes {
  Client = "client",
  Business = "business",
}

export enum InquiryRecepientTypes {
  Client = InquiryLogSourceTypes.Client,
  Business = InquiryLogSourceTypes.Business,
  Partner = "partner",
}

export enum InquiryLogEventTypes {
  Submit = "inquiry_submitted",
  Change = "inquiry_changed",
  Email = "email",
  Closed = "inquiry_closed",
  Reopen = "inquiry_reopened",
  PaymentReqested = "payment_requested",
  PaymentMade = "payment_made",
  Booked = "inquiry_booked",
  Complete = "inquiry_complete",
  StatusCheck = "status_check",
  SatisfactionCheck = "satisfaction_check",
  NPS = "inquiry_feedback"
}

export enum ChangedShortProperties {
  StartDate = "Start date",
  EndDate = "End date",
  DateFlexibility = "Date flexibility",
  Guests = "Guests",
}

export enum CloseInquiryOptions {
  BUDGET_LOW = "Budget was too low",
  NOT_AVAILABLE_DATES = "Requested dates are unavailable",
  NO_AMENITY = "Client is looking for an amenity not offered",
  ANOTHER_BUSINESS = "Client booked with another business",
  NO_EVENT = "Client is not longer having the event",
  UNRESPONSIVE_BUSINESS = "Client was unresponsive",
  OTHER = "Other",
}

export enum EmailAttachmentContentTypes {
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  MP4 = "video/mp4",
  OGV = "video/ogg",
}

export enum PriceDurationBE {
  PerDay = "per_day",
  PerHalfDay = "per_half_day",
  PerHour = "per_hour",
  PerPerson = "per_person",
}

export enum PriceDurationFE {
  PerDay = "Per Day",
  PerHalfDay = "Per Half Day",
  PerHour = "Per Hour",
  PerPerson = "Per Person",
}

export enum ListingStatus {
  DRAFT = "draft",
  PENDING_REVIEW = "pending_review",
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
}

