import { useEffect, useMemo, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Typography } from "@mui/material";
import { FilterTagLabel } from "@/src/component/base/FilterTagLabel";
import { useApp } from "@/src/component/provider";
import { AppSearchFilterType } from "@/src/type";
import { AppSearchFilterOption } from "@/src/type/AppSearchFilterType";
import styles from "./FilterPaneSectionV2.module.scss";

interface Props extends AppSearchFilterType.AppSearchFilter {
  filtersStateRef: React.MutableRefObject<Record<string, string[]>>;
}

function FilterPanelSection({
  title,
  dataKey,
  options = [],
  startOpen = false,
  isExpandable = true,
  filtersStateRef,
}: Props) {
  const app = useApp();
  const MAX_VISIBLE_OPTIONS = 13;

  const [isOpen, setIsOpen] = useState<boolean>(startOpen);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [selectedTagValues, setSelectedTagValues] = useState<string[]>([]);

  const visibleOptions = useMemo(
    () => (showAll ? options : options.slice(0, MAX_VISIBLE_OPTIONS)),
    [showAll, options],
  );

  const updateFilterState = (newValues: string[]) => {
    setSelectedTagValues(newValues);
    filtersStateRef.current = { ...filtersStateRef.current, [dataKey]: newValues };
  };

  const toggleTagSelection = (tag: AppSearchFilterOption) => {
    const updatedValues = selectedTagValues.includes(tag.value)
      ? selectedTagValues.filter((val) => val !== tag.value)
      : [...selectedTagValues, tag.value];
    updateFilterState(updatedValues);
  };

  const toggleExpand = () => setIsOpen((prevOpen) => !prevOpen);
  const toggleShowAll = () => setShowAll((prev) => !prev);

  useEffect(() => {
    const appliedTags: string[] = app.searchFilters?.getFilterData(dataKey) ?? [];
    if (appliedTags) {
      updateFilterState(appliedTags);
      setIsOpen(appliedTags.length > 0 || startOpen);
    }
    const isAppliedTagAfterShowMore = appliedTags.some(
      (el) => options.findIndex((opt) => opt.value === el) > MAX_VISIBLE_OPTIONS
    );
    
    if (isAppliedTagAfterShowMore) {
      setShowAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderToggleIcon = () =>
    isOpen ? (
      <ExpandLessIcon className={styles.expandIcon} />
    ) : (
      <ExpandMoreIcon className={styles.expandIcon} />
    );

  return (
    <Box className={styles.filtersSection}>
      <Box className={styles.filtersSectionTitleWrapper}>
        <Typography className={styles.filtersSectionTitle}>{title}</Typography>
        {isExpandable && (
          <button
            onClick={toggleExpand}
            className={styles.expandIconWrapper}
            aria-label={`${isOpen ? "Collapse" : "Expand"} ${title}`}
            aria-expanded={isOpen}
          >
            {renderToggleIcon()}
          </button>
        )}
      </Box>
      <Collapse in={isOpen}>
        <Box className={styles.filtersSectionOptions}>
          {visibleOptions.map((option) => (
            <FilterTagLabel
              key={option.value}
              tag={option}
              isSelected={selectedTagValues?.includes(option.value)}
              onClick={toggleTagSelection}
            />
          ))}
          {options.length > MAX_VISIBLE_OPTIONS && (
            <Typography
              component="button"
              className={styles.showMoreBtn}
              onClick={toggleShowAll}
              aria-label={`${showAll ? "Show less" : "Show more"} ${title}`}
            >
              {showAll ? "Show less" : "Show more"}
            </Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}

export default FilterPanelSection;
