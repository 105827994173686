import { useCallback, useEffect, useMemo } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import cn from "classnames";
import Image from "next/image";
import ToggleMapIcon from "@/public/icons/pinMapToggleIcon.svg";
import { ENV } from "@/src/const";
import { useBreakPointUp, useNextNavigation } from "@/src/hook";
import { ActivityLogic } from "@/src/model";
import { ListingDisplayVariant } from "@/src/type";
import styles from "./MapButton.module.scss";

interface MapButtonProps {
  viewVariant: keyof typeof ListingDisplayVariant;
  handleMapToggle: (viewVariant?: keyof typeof ListingDisplayVariant) => void;
  isCitySelected: boolean;
}

function MapButton({ viewVariant, isCitySelected, handleMapToggle }: MapButtonProps) {
  const nextNavigation = useNextNavigation();
  const isProd = ENV === "prod";
  const isDesktop = useBreakPointUp("md");

  /**
   * make the map open / close with url parameters changes
   */
  useEffect(() => {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    if (!params.get("viewVariant")) {
      if (viewVariant === ListingDisplayVariant.full && !isProd && isDesktop) {
        handleMapToggle(ListingDisplayVariant.map);
      }
      //temporary for prod show full by default
      if (viewVariant === ListingDisplayVariant.map && isProd) {
        handleMapToggle(ListingDisplayVariant.full);
      }
      return;
    }

    if (viewVariant !== params.get("viewVariant")) {
      handleMapToggle();
    }
  }, [viewVariant, nextNavigation.searchParams]);

  const handleFormat = () => {
    ActivityLogic.toActivityService({
      action: "changeMapView",
      actionMessage: viewVariant === ListingDisplayVariant.map ? "closed" : "open",
      locationInApp: "MapButton.ts",
    });
    const toChange =
      viewVariant === ListingDisplayVariant.map
        ? ListingDisplayVariant.full
        : ListingDisplayVariant.map;
    nextNavigation.updateQueryString("viewVariant", toChange);
  };

  const currentColorClass = useMemo(() => {
    if (isCitySelected) {
      return viewVariant === ListingDisplayVariant.map
        ? styles.colorSelectedViewVariantMap
        : styles.colorSelectedViewVariantFull;
    }
    return viewVariant === ListingDisplayVariant.map
      ? styles.colorUnselectedViewVariantMap
      : styles.colorUnselectedViewVariantFull;
  }, [viewVariant, isCitySelected]);

  const currentBackgroundClass = useMemo(() => {
    if (viewVariant === ListingDisplayVariant.map) {
      return isCitySelected
        ? styles.bgColorSelectedViewVariantMap
        : styles.bgColorUnselectedVariantMap;
    }
    return styles.bgColorViewVariantFull;
  }, [isCitySelected, viewVariant]);

  const iconStyles = useMemo(() => {
    if (viewVariant === ListingDisplayVariant.map) {
      return { filter: isCitySelected ? "invert(100%)" : "none" };
    }
    return { filter: isCitySelected ? "none" : "invert(100%)" };
  }, [isCitySelected, viewVariant]);

  const getToggleButtonClasses = useCallback(() => {
    return [styles.toggleButtonCommon, currentColorClass, currentBackgroundClass];
  }, [currentColorClass, currentBackgroundClass]);

  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        color="secondary"
        value={viewVariant === ListingDisplayVariant.map ? [ListingDisplayVariant.map] : []}
        onChange={handleFormat}
        aria-label="Toggle Map On / Off"
        size="small"
      >
        <ToggleButton value="map" aria-label="Map Button" className={cn(getToggleButtonClasses())}>
          <Image src={ToggleMapIcon} alt="toggle map card icon" style={iconStyles} />
          {viewVariant === ListingDisplayVariant.full ? "Show Map" : "Hide Map"}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default MapButton;
