import cn from "classnames";
import { BaseButton, ImgFromCDN } from "@/src/component/base";
import { BlaceV2Type } from "@/src/type";
import { ImageFromCDN } from "@/src/util";
import styles from "./NoResults.module.scss";

const venuesImg = "NoVenuesBanner.png";
const vendorsImg = "NoVendorsBanner.png";

interface NoResultsProps {
  onReset: () => void;
  listingType: BlaceV2Type.SearchTypes;
};

const NoResults = ({
  onReset,
  listingType,
}: NoResultsProps) => {
  const title = listingType === BlaceV2Type.SearchTypes.Venue ? 
    "Oops... no venues match this exact search"
    :
    "Oops... no vendors match this exact search";
  const imgUrl = ImageFromCDN.imageSizeAndQuality(
    listingType === BlaceV2Type.SearchTypes.Venue ? venuesImg : vendorsImg,
    90,
    listingType === BlaceV2Type.SearchTypes.Venue ? 140 : 200,
    true
  );

  return (
    <div className={cn(styles.noResults)}>
      <ImgFromCDN
        src={imgUrl}
        alt="No results"
        width={listingType === BlaceV2Type.SearchTypes.Venue ? 140 : 200}
        height={listingType === BlaceV2Type.SearchTypes.Venue ? 177 : 139}
        className={styles.businessImage}
      />
      <p className={cn(styles.noResultsTitle)}>{title}</p>
      <BaseButton className={cn(styles.resetFiltersButton)} onClick={onReset}>Reset filters</BaseButton>
    </div>
  );
};

export default NoResults;
