import React from "react";
import { InputAdornment, MenuItem, OutlinedInput, Select, SelectProps } from "@mui/material";
import cn from "classnames";
import { BaseIcon } from "@/src/component/base/BaseIcon";
import styles from "./BaseSelect.module.scss";

interface BaseSelect extends SelectProps<string> {
  startIcon: string;
  startIconAlt: string;
  options: { label: string; value: string }[];
  defaultOption: string;
  className: string;
  defaultPlaceholder: string;
}

function BaseSelect({
  startIcon,
  startIconAlt,
  defaultOption,
  defaultPlaceholder,
  options,
  className,
  ...props
}: BaseSelect) {
  return (
    <Select
      className={cn(styles.baseSelect, className)}
      displayEmpty
      input={
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <BaseIcon iconFileName={startIcon} iconAlt={startIconAlt} />
            </InputAdornment>
          }
        />
      }
      renderValue={(selected) => {
        return selected
          ? options.find((option) => option.value === selected)?.label
          : defaultPlaceholder;
      }}
      {...props}
    >
      <MenuItem value="">{defaultOption}</MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default BaseSelect;
