import React, { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, InputAdornment, SelectChangeEvent, TextField } from "@mui/material";
import cn from "classnames";
import { useDebouncedCallback } from "use-debounce";
import { BaseButton, BaseIcon, BaseSelect } from "@/src/component/base";
import { useApp } from "@/src/component/provider";
import { ExtraFiltersModal } from "@/src/component/view/Listing/component/ExtraFiltersModal";
import { useBreakPointDown } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { AppSearchFilterOption } from "@/src/type/AppSearchFilterType";
import { ListingQuickFilterValues } from "@/src/type/component/Listing";
import { NumberHelper } from "@/src/util";
import FiltersHelper from "@/src/util/helper/FiltersHelper";
import styles from "./QuickFilters.module.scss";

enum VenueQuickFilterValues {
  CAPACITY = "capacityV2",
  EVENT_TYPE = "eventType",
  REGIONS = "regions",
}

enum VendorQuickFilterValues {
  PRICING = "vendorPricingV2",
  TYPE = "vendorTypeV2",
  REGIONS = "regions",
}

interface QuickFiltersProps {
  onSearchClick?: () => void;
}

function QuickFilters({ onSearchClick }: QuickFiltersProps) {
  const app = useApp();
  const currentListingsType = app.searchFilters?.filterState.dataType?.[0];
  const [guestCount, setGuestCount] = useState("");
  const [regionSelected, setRegionSelected] = useState("");
  const [eventTypeSelected, setEventTypeSelected] = useState<AppSearchFilterOption | null>(null);

  const isMobile = useBreakPointDown("md");

  const eventTypeOptions = useMemo(() => SearchLogic.FILTERS.eventType.options || [], []);
  const regionOptions = useMemo(() => SearchLogic.FILTERS.regions.options || [], []);
  const vendorTypeOptions = useMemo(() => SearchLogic.FILTERS.vendorTypeV2.options || [], []);
  const vendorPricingOptions = useMemo(() => SearchLogic.FILTERS.vendorPricingV2.options || [], []);

  const eventTypeValue =
    eventTypeOptions.find(
      (el) =>
        el.value ===
        (isMobile
          ? eventTypeSelected?.value
          : app.searchFilters?.getFilterData(ListingQuickFilterValues.EVENT_TYPE)),
    ) || null;

  const regionSelectValue = isMobile
    ? regionSelected
    : (app.searchFilters?.getFilterData(ListingQuickFilterValues.REGIONS) ?? "");

  const setSingleChoice = (changeTo: string, dataKey: string) => {
    const currentFilter = app.searchFilters?.getFilterData(dataKey) ?? "";
    const newValue = changeTo === currentFilter ? "" : changeTo;
    app.searchFilters?.setFilterData(dataKey, newValue);
    SearchLogic.trackFilterUsed(changeTo, dataKey, "QuickFilters.tsx");
  };

  const debounceGuestValue = useDebouncedCallback((guestValue: string) => {
    app.searchFilters?.setFilterData(VenueQuickFilterValues.CAPACITY, guestValue);
    SearchLogic.trackFilterUsed(guestValue, VenueQuickFilterValues.CAPACITY, "QuickFilters.tsx");
  }, 500);

  const handleGuestCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const guestValue = NumberHelper.clearInputValue(event.target.value);
    setGuestCount(guestValue);
    if (!isMobile) {
      debounceGuestValue(guestValue);
    }
  };

  const handleRegionChange = (e: SelectChangeEvent) => {
    if (isMobile) {
      setRegionSelected(e.target.value);
      return;
    }
    setSingleChoice(e.target.value, ListingQuickFilterValues.REGIONS);
  };

  const handleEventTypeChange = (e: SyntheticEvent, val: AppSearchFilterOption | null) => {
    if (isMobile) {
      setEventTypeSelected(val);
      return;
    }
    setSingleChoice(val?.value || "", ListingQuickFilterValues.EVENT_TYPE);
  };

  const handleMobileSearch = () => {
    const newFilterData = [
      { property: ListingQuickFilterValues.EVENT_TYPE, data: eventTypeSelected?.value || "" },
      { property: ListingQuickFilterValues.REGIONS, data: regionSelected },
      { property: ListingQuickFilterValues.CAPACITY, data: guestCount },
    ];
    app.searchFilters?.setFilterMultiData(newFilterData);
    newFilterData.forEach((filterData) =>
      SearchLogic.trackFilterUsed(filterData.data, filterData.property, "QuickFilters.tsx"),
    );
    onSearchClick && onSearchClick();
  };

  useEffect(() => {
    if (currentListingsType !== BlaceV2Type.SearchTypes.Vendor) {
      const guestFromUrl = app.searchFilters?.getFilterData(VenueQuickFilterValues.CAPACITY);
      setGuestCount((guestFromUrl as string) ?? "");
    }
  }, [currentListingsType]);

  useEffect(() => {
    if (isMobile) {
      const regionFromUrl = app.searchFilters?.getFilterData(VenueQuickFilterValues.REGIONS);
      const eventTypeFromUrl = app.searchFilters?.getFilterData(VenueQuickFilterValues.EVENT_TYPE);
      setRegionSelected((regionFromUrl as string) ?? "");
      setEventTypeSelected(eventTypeOptions.find((el) => el.value === eventTypeFromUrl) || null);
    }

    const filtersWereReset = FiltersHelper.areFiltersReset(app.searchFilters?.filterState || {});

    if (filtersWereReset) {
      setGuestCount("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, app.searchFilters?.filterState]);

  return (
    <Box className={styles.quickFiltersContainer}>
      {currentListingsType !== BlaceV2Type.SearchTypes.Vendor ? (
        <>
          <Autocomplete
            disablePortal
            options={eventTypeOptions}
            getOptionLabel={(option) => option.label}
            className={styles.eventSelect}
            value={eventTypeValue}
            onChange={handleEventTypeChange}
            popupIcon={null}
            renderInput={(params) => (
              <TextField
                placeholder="Event type"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <BaseIcon iconFileName="groupShareIcon" iconAlt="event type" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <TextField
            placeholder="Guest count"
            className={styles.guestCount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BaseIcon iconFileName="profileGreyIcon" iconAlt="guest count" />
                </InputAdornment>
              ),
            }}
            onChange={handleGuestCountChange}
            value={NumberHelper.formatFormInputValue(guestCount)}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
        </>
      ) : (
        <>
          <BaseSelect
            id="vendor-type-select"
            className={styles.filtersSelect}
            value={app.searchFilters?.getFilterData(VendorQuickFilterValues.TYPE) ?? ""}
            onChange={(e) => setSingleChoice(e.target.value, VendorQuickFilterValues.TYPE)}
            displayEmpty
            defaultPlaceholder="Vendor Type"
            options={vendorTypeOptions}
            startIcon="videoIcon"
            startIconAlt="vendor type selection"
            defaultOption="Any Type"
          />
          <BaseSelect
            id="vendor-pricing-select"
            className={styles.filtersSelect}
            value={app.searchFilters?.getFilterData(VendorQuickFilterValues.PRICING) ?? ""}
            onChange={(e) => setSingleChoice(e.target.value, VendorQuickFilterValues.PRICING)}
            options={vendorPricingOptions}
            startIcon="dollarIcon"
            startIconAlt="vendor pricing selection"
            defaultOption="Any Pricing"
            defaultPlaceholder="Vendor Pricing"
          />
        </>
      )}
      <BaseSelect
        id="city-select"
        className={cn(styles.filtersSelect, styles.citySelect)}
        value={regionSelectValue as string}
        onChange={handleRegionChange}
        options={regionOptions}
        startIcon="locationPinOutlinedIcon"
        startIconAlt="location selection"
        defaultOption="Any Location"
        defaultPlaceholder="Location"
      />

      <Box className={styles.extraFiltersWrapper} onClick={() => app.setSearchFocus(true)}>
        <BaseIcon iconFileName="filterIcon" iconAlt="extra filters" iconSize={20} />
        {SearchLogic.countAppliedFilters(currentListingsType, app.searchFilters?.getFilterData) ||
          ""}
      </Box>
      <ExtraFiltersModal isOpen={app.searchFocus} onClose={() => app.setSearchFocus(false)} />

      <BaseButton onClick={handleMobileSearch} className={styles.searchButton}>
        Search
      </BaseButton>
    </Box>
  );
}

export default QuickFilters;
