import { ChangeEvent, KeyboardEvent, SyntheticEvent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { Autocomplete, FormControl, InputAdornment, TextField, Typography } from "@mui/material";
import { BaseButton, DRAWER_FIX_TO_HEIGHT, DrawerFixedToHeader } from "@/src/component/base";
import { FitlerPanel } from "@/src/component/partial/FilterPanel";
import { useApp } from "@/src/component/provider";
import { ENV } from "@/src/const";
import { useBreakPointDown } from "@/src/hook";
import { ActivityLogic, SearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import styles from "./GlobalSearch.module.scss";

interface GlobalSearchProps {
  setIsFocused: (val: boolean) => void;
  isFocused: boolean;
}

type SuggestionOptions = { title: string };

function GlobalSearch({ setIsFocused, isFocused }: GlobalSearchProps) {
  const app = useApp();
  const isProdENV = ENV === "prod";
  const isMobile = useBreakPointDown("md");
  const [searchValue, setSearchValue] = useState<string>("");
  const [canClearSearch, setCanClearSearch] = useState<boolean>(false);
  const [openPopper, setOpenPopper] = useState<boolean>(false);
  const [options, setOptions] = useState<SuggestionOptions[]>([]);

  function trackSearchTerm(val?: string | null) {
    if (!val) {
      return;
    }
    ActivityLogic.ga4Tracking("search", {
      search_term: val,
    });
    ActivityLogic.toActivityService({
      action: "search",
      actionId: val,
      actionIdType: "searchValue",
      locationInApp: "GlobalSearch.tsx",
    });
  }

  function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value ?? "");
  }

  function onAutoCompleteChangeHandler(
    _event: SyntheticEvent<Element, Event>,
    value: string | null,
  ) {
    app.setSearch({ searchTerm: value ?? "", searchQueryType: "simple" });
    setCanClearSearch(true);
    setIsFocused(false);
    trackSearchTerm(value);
  }

  function onKeyDownHandler(event?: KeyboardEvent<HTMLDivElement>) {
    if (event?.key === "Enter") {
      app.setSearch({ searchTerm: searchValue ?? "", searchQueryType: "full" });
      setCanClearSearch(true);
      setOpenPopper(false);
      setIsFocused(false);
      trackSearchTerm(searchValue);
    }
  }

  useEffect(() => {
    if (!app.searchTerm && canClearSearch) {
      setSearchValue("");
      setOptions([]);
      setCanClearSearch(false);
    }
  }, [app.searchTerm, canClearSearch]);

  useEffect(() => {
    if ((searchValue ?? "").length <= 1) {
      return;
    }

    const timeoutHolder = setTimeout(async () => {
      const response = await BlaceV2API.SearchServiceV2.postSearchSuggestion(
        SearchLogic.defaultSuggestions(searchValue),
      );

      if (Array.isArray(response.body?.payload?.value)) {
        const opts: SuggestionOptions[] = [];
        const noDups: string[] = [];
        for (const value of response.body?.payload?.value ?? []) {
          if (!noDups.includes(value["@search.text"])) {
            opts.push({ title: value["@search.text"] });
            noDups.push(value["@search.text"]);
          }
        }
        setOptions(opts);
      } else {
        setOptions([]);
      }
    }, 250);
    return () => clearTimeout(timeoutHolder);
  }, [searchValue]);

  return (
    <>
      <FormControl
        variant="standard"
        classes={{
          root: styles.globalSearchFieldGroup,
        }}
      >
        <Autocomplete
          id={"GlobalSearch.Autocomplete"}
          disableClearable={true}
          options={options.map((option) => option.title)}
          freeSolo={true}
          value={searchValue}
          open={openPopper}
          autoFocus={false}
          onOpen={() => setOpenPopper(true)}
          onClose={() => setOpenPopper(false)}
          classes={{
            popper: styles.autoCompleteDropDownPopper,
          }}
          openOnFocus={false}
          onHighlightChange={() => setIsFocused(true)}
          onChange={onAutoCompleteChangeHandler}
          renderInput={(params) => (
            <TextField
              {...params}
              classes={{
                root: styles.globalSearchInput,
              }}
              autoFocus={false}
              onChange={onChangeHandler}
              hiddenLabel={true}
              fullWidth={true}
              placeholder="Search BLACE"
              onKeyDown={onKeyDownHandler}
              InputProps={{
                ...params.InputProps,
                // Remove startAdornment when new filters will be done
                startAdornment: isMobile && ENV === "prod" ? (
                  <InputAdornment
                    position="start"
                    color="inherit"
                    onClick={() => setIsFocused(!isFocused)}
                  >
                    <TuneIcon color="inherit" />
                  </InputAdornment>
                ) : undefined,
                endAdornment: (
                  <InputAdornment
                    className={
                      searchValue.length > 1
                        ? styles.globalSearchSearchIconActive
                        : styles.globalSearchSearchIconDefault
                    }
                    position="end"
                  >
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
      {isProdENV && (
        <DrawerFixedToHeader
          id="GlobalSearch.DrawerFixedToHeader"
          anchor="top"
          disableEnforceFocus={true}
          disableAutoFocus={true}
          disableRestoreFocus={true}
          keepMounted={isMobile}
          onClose={() => setIsFocused(false)}
          open={isFocused}
          SlideProps={{
            direction: "down",
          }}
          sx={{
            top: DRAWER_FIX_TO_HEIGHT,
            zIndex: "var(--zIndexGlobalSearchDrawer)",
          }}
        >
          <div className={styles.globalSearchDrawerContainer}>
            <div className={styles.globalSearchDrawerClose}>
              <BaseButton
                onClick={() => setIsFocused(false)}
                variant="contained"
                type="button"
                size="small"
              >
                Exit
              </BaseButton>
            </div>
            <div className={styles.globalSearchDrawerInner}></div>
            <div className={styles.globalSearchDrawerLabel}>
              <Typography variant="body1">Filters</Typography>
            </div>
            <FitlerPanel />
          </div>
        </DrawerFixedToHeader>
      )}
    </>
  );
}

export default GlobalSearch;
