import { createContext, useContext } from "react";

type PageLayoutContextType = {
  showListingQuickFiltersInHeader: boolean;
  setShowListingQuickFiltersInHeader: (show: boolean) => void;
};

const PageLayoutContextDefaultValues: PageLayoutContextType = {
  showListingQuickFiltersInHeader: false,
  setShowListingQuickFiltersInHeader: () => {},
};

export const PageLayoutContext = createContext<PageLayoutContextType>(
  PageLayoutContextDefaultValues,
);

export function usePageLayoutContext() {
  return useContext(PageLayoutContext);
}
