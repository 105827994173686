import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Drawer } from "@mui/material";
import cn from "classnames";
import { ExitButton } from "@/src/component/base";
import { useSmallHeighLargeWidth } from "@/src/hook";
import styles from "./DetailLayout.module.scss";

interface DetailLayoutProps {
  ImageGrid: ReactElement;
  LeftContainer: ReactElement;
  RightContainer: ReactElement;
  BottomContainer: ReactElement;
  FooterContainer: ReactElement;
  elementIdToTriggerFooter: string;
  inquiryDrawerOpen: boolean;
  setInquiryDrawerClose: () => void;
}

function DetailLayout({
  ImageGrid,
  LeftContainer,
  RightContainer,
  FooterContainer,
  BottomContainer,
  elementIdToTriggerFooter,
  inquiryDrawerOpen,
  setInquiryDrawerClose,
}: DetailLayoutProps) {
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const heightLimit = useSmallHeighLargeWidth();

  /**
   * when the bottom of the inquiry intersections the top head
   * show the footer on mobile only
   */
  useEffect(() => {
    function handleScroll() {
      const observer = document.getElementById(elementIdToTriggerFooter);
      if ((observer?.getBoundingClientRect()?.top ?? 9999) < 81 * 3) {
        if (!showFooter) {
          setShowFooter(true);
        }
      } else {
        if (showFooter) {
          setShowFooter(false);
        }
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showFooter, elementIdToTriggerFooter]);

  return (
    <>
      <div className={styles.detailContainer}>
        {/**Image Grid at top of page*/}
        <div className={styles.detailContainerImageGrid}>{ImageGrid}</div>
        <div className={styles.detailInner}>
          {/**Left container for details*/}
          <div className={styles.detailInnerLeft}>{LeftContainer}</div>
          {/**Right container for sticky form*/}
          <div className={styles.detailInnerRight}>
            <section
              className={cn(
                styles.detailInquiryContainer,
                heightLimit ? styles.detailInquiryContainerScaleDown : undefined
              )}
            >
              {RightContainer}
            </section>
          </div>
        </div>
        <div className={styles.detailInnerBottom}>{BottomContainer}</div>
      </div>
      {/**Create inquiry button appears on mobile when create button card goes about header*/}
      <div className={styles.detailFooter}>
        <div
          className={cn(
            styles.detailFooterInner,
            showFooter
              ? styles.detailFooterInnerActive
              : styles.detailFooterInnerHidden
          )}
        >
          {FooterContainer}
        </div>
      </div>
      {/**Drawer for the inquiry form on mobile*/}
      <Drawer
        id="DetailLayout.InquiryFormDrawer"
        open={inquiryDrawerOpen}
        anchor="bottom"
        onClose={setInquiryDrawerClose}
        PaperProps={{
          sx: {
            top: "0px",
          },
        }}
        sx={{
          zIndex: 1000,
        }}
      >
        <ExitButton
          className={styles.detailFooterFormExit}
          onExit={setInquiryDrawerClose}
        />
        {RightContainer}
      </Drawer>
    </>
  );
}

export default DetailLayout;
