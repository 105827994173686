import React, { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import { KEYS } from "@/src/const";
import { SharedConfigManager } from "@/src/util";

interface BaseIconProps
  extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  iconFileName: string;
  iconAlt: string;
  iconSize?: number;
  className?: string;
}

const BaseIcon = React.forwardRef<HTMLImageElement, BaseIconProps>(function BaseIcon(
  { iconFileName, iconAlt, iconSize = 16, className, ...props }: BaseIconProps,
  ref,
) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  return (
    <img
      src={`${SVG_URL}/${iconFileName}.svg`}
      alt={iconAlt}
      width={iconSize}
      height={iconSize}
      className={className}
      ref={ref}
      {...props}
    />
  );
});

export default BaseIcon;
