import React, { useRef, useState } from "react";
import cn from "classnames";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BaseIcon, ImgBgResponsive } from "@/src/component/base";
import { ComponentType } from "@/src/type";
import styles from "./ListingCarousel.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface CarouselImageProps extends CarouselProps {
  images: ComponentType.Carousel.ImageType[];
  load: "eager" | "lazy";
  imageClassName: string;
  navIconClassName?: string;
}

function ListingCarousel({ images, imageClassName, load, navIconClassName }: CarouselImageProps) {
  const [, setSwiperInit] = useState(false);
  const nextButtonRef = useRef<HTMLImageElement>(null);
  const prevButtonRef = useRef<HTMLImageElement>(null);

  const preventGoToDetailPage = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
  };

  return (
    <div className={styles.listingCarousel}>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        className={styles.carousel}
        navigation={{
          nextEl: nextButtonRef.current,
          prevEl: prevButtonRef.current,
          enabled: true,
        }}
        modules={[Pagination, Navigation]}
        onInit={() => setSwiperInit(true)}
      >
        {(images ?? []).map((image, i) => (
          <SwiperSlide key={i + image.url} className={styles.swiperSlide}>
            <ImgBgResponsive
              className={imageClassName}
              imageUrl={image.url}
              lazy={load}
              prefetch={false}
            ></ImgBgResponsive>
          </SwiperSlide>
        ))}
      </Swiper>
      <BaseIcon
        iconFileName="arrowRightDarkIcon"
        ref={nextButtonRef}
        className={cn(styles.navIcon, navIconClassName, styles.rightArrow)}
        iconAlt="next"
        iconSize={20}
        onClick={preventGoToDetailPage}
      />
      <BaseIcon
        iconFileName="arrowLeftDarkIcon"
        ref={prevButtonRef}
        className={cn(styles.navIcon, navIconClassName, styles.leftArrow)}
        iconAlt="prev"
        iconSize={20}
        onClick={preventGoToDetailPage}
      />
    </div>
  );
}

export default ListingCarousel;
