import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import cn from "classnames";
import { useApp } from "@/src/component/provider";
import { SearchLogic } from "@/src/model";
import { ListingQuickFilterValues } from "@/src/type/component/Listing";
import styles from "./MobileQuickFiltersInfo.module.scss";

interface MobileQuickFiltersProps {
  onClick: () => void;
}

function MobileQuickFilters({ onClick }: MobileQuickFiltersProps) {
  const app = useApp();
  const eventTypeOptions = useMemo(() => SearchLogic.FILTERS.eventType.options || [], []);
  const regionOptions = useMemo(() => SearchLogic.FILTERS.regions.options || [], []);
  const appliedGuestCount = app.searchFilters?.getFilterData<string>(
    ListingQuickFilterValues.CAPACITY,
  );

  const appliedEventType = eventTypeOptions.find(
    (el) =>
      el.value === app.searchFilters?.getFilterData<string>(ListingQuickFilterValues.EVENT_TYPE),
  )?.label;

  const appliedLocation = regionOptions.find(
    (region) =>
      region.value === app.searchFilters?.getFilterData<string>(ListingQuickFilterValues.REGIONS),
  )?.label;

  return (
    <Stack className={styles.filtersContainer} onClick={onClick}>
      <Stack>
        <Typography className={styles.eventType}>
          {appliedEventType || "What are you planning?"}
        </Typography>
        <Stack gap={2} direction="row">
          <Typography className={cn(styles.guestCount, { [styles.applied]: !!appliedGuestCount })}>
            {appliedGuestCount
              ? `${appliedGuestCount} ${appliedGuestCount === "1" ? "guest" : "guests"}`
              : "Any guest"}
          </Typography>
          {!appliedLocation && <Typography className={styles.location}>Any location</Typography>}
        </Stack>
      </Stack>
      {appliedLocation && (
        <Typography className={cn(styles.location, styles.applied)}>{appliedLocation}</Typography>
      )}
    </Stack>
  );
}

export default MobileQuickFilters;
